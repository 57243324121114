"use strict";
// Example palette (atlassian): https://atlassian.design/foundations/color
// Potential palette: https://accessiblepalette.com/?lightness=98.2,93.9,86,76,64,54,48,38,27,18&ff1122=0,0&ffa400=0,-10&00ff3f=0,0&589dff=0,0&c8cfde=0,0
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLOR_ALIASES = exports.PALETTE = void 0;
// const PALETTE = {
//   transparent: "transparent",
//   current: "currentColor",
//   white: "#fff",
//   black: "#000",
//   neutral: {
//     50: "#f5f6f7", // (Off-white)
//     100: "#edeef0",
//     200: "#e4e5e7",
//     300: "#c9cfd9",
//     400: "#a1a8b6", // (Bluey-gray)
//     500: "#8f97a5",
//     600: "#767e8d",
//     700: "#606d80",
//     800: "#262b33",
//   },
//   green: {
//     50: "#f0fff5", // H 139 L 97%
//     100: "#e5ffed", // H 138 L 95% (Pastel-green)
//     200: "#ccffdb", // H 138 L 90%
//     500: "#00ff51", // H 139 L 50% (Primary-green)
//     600: "#00d544", // H 139 L 42% (Success)
//     700: "#00b339", // H 139 L 35% (Mid-green)
//   },
//   red: {
//     50: "#fff0f1", // H 356 L 97%
//     100: "#ffe6e8", // H 355 L 95% (Pastel-pink)
//     400: "#ff3b49", // H 356 L 62% (red)
//     500: "#ff1122", // H 356 L 53% (Error)
//   },
//   yellow: {
//     50: "#fffaf0", // H 39 L 97%
//     200: "#fff5cc", // H 48 L 90% (Pastel-yellow)
//     400: "#fdc40c", // H 46 L 52% (Yellow)
//     500: "#ffa400", // H 39 L 50% (Warning)
//   },
// ONLY USED FOR BANNER
//   orange: {
//     200: "#ffdfcc", // H 22 L 90% (Pastel-orange)
//     500: "#ff5e00", // H 22 L 50% (Orange)
//   },
// newYellow: {
//   '50': '#FFFAE5',
//   '100': '#FFF4CC',
//   '200': '#FFE599',
//   '300': '#FFD366',
//   '400': '#FFBD33',
//   '500': '#FFA400',
//   '600': '#CC7C00',
//   '700': '#995800',
//   '800': '#663700',
//   '900': '#331A00'
// }
//   blue: {
//     50: "#f0f6ff", // H 216 L 97%
//     100: "#deebff", // H 216 L 94% (Pastel-blue)
//     400: "#589dff", // H 215 L 67% (Blue)
//   },
// ONLY USED FOR BANNER
//   purple: {
//     100: "#e1e2ff", // H 238 L 94% (Pastel-purple)
//     400: "#696cff", // H 239 L 71% (Purple)
//   },
// // } as const;
// const BRAND_COLOR_ALIASES = {
//   "off-white": PALLETE.gray[50],
//   "primary-green": PALLETE.green[500],
//   "mid-green": PALLETE.green[700],
//   "pastel-green": PALLETE.green[100],
//   "brand-yellow": PALLETE.yellow[500],
//   "brand-orange": PALLETE.orange[500],
//   "brand-red": PALLETE.red[400],
//   "brand-pink": PALLETE.red[100],
//   "brand-purple": PALLETE.purple[400],
//   "brand-blue": PALLETE.blue[400],
//   "pastel-yellow": PALLETE.yellow[200],
//   "pastel-orange": PALLETE.orange[200],
//   "pastel-pink": PALLETE.red[100],
//   "pastel-purple": PALLETE.purple[100],
//   "pastel-blue": PALLETE.blue[100],
// } as const;
exports.PALETTE = {
    transparent: "transparent",
    current: "currentColor",
    white: "#fff",
    black: "#000",
    "off-white": "#f5f6f7",
    gray: {
        100: "#edeef0",
        200: "#e4e5e7",
        300: "#c9cfd9",
        400: "#a1a8b6",
        500: "#8f97a5",
        600: "#767e8d",
        700: "#606d80",
        800: "#262b33",
    },
    "primary-green": "#00ff51",
    red: "#ff3b49",
    pink: "#ff838b",
    orange: "#ff5e00",
    yellow: "#fdc40c",
    "mid-green": "#00b339",
    blue: "#589dff",
    purple: "#696cff",
    "pastel-pink": "#ffe6e8",
    "pastel-orange": "#ffdfcc",
    "pastel-yellow": "#fff5cc",
    "pastel-green": "#e5ffed",
    "pastel-blue": "#deebff",
    "pastel-purple": "#e1e2ff",
    success: "#00d544",
    error: "#ff1122",
    warning: "#ffa400",
};
exports.COLOR_ALIASES = {
    "hi-contrast": exports.PALETTE.black,
    "lo-contrast": exports.PALETTE.white,
    surface: exports.PALETTE.white,
    "active-link": exports.PALETTE["mid-green"],
    "secondary-link": exports.PALETTE.gray[400],
    disabled: exports.PALETTE.gray[300],
};
