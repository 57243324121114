export enum HandledGQLErrorCodes {
  UNAUTHENTICATED = "UNAUTHENTICATED",
  FORBIDDEN = "FORBIDDEN",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
}

const DEFAULT_ERROR_MESSAGE = "An unexpected error occurred. Please try again later.";

const ERROR_MESSAGE_MAP: { [key in HandledGQLErrorCodes]: string } = {
  INTERNAL_SERVER_ERROR: DEFAULT_ERROR_MESSAGE,
  UNAUTHENTICATED: "You must be logged in to perform this action.",
  FORBIDDEN: "You do not have permission to perform this action.",
};

export class SanitizedGQLError extends Error {
  code: keyof typeof HandledGQLErrorCodes;

  originalMessage: string;

  constructor(error: any) {
    const code = error[0].extensions.code as HandledGQLErrorCodes;

    // Call original Error super
    super(ERROR_MESSAGE_MAP[code] ?? DEFAULT_ERROR_MESSAGE);

    this.name = "SanitizedGQLError";
    this.code = code;
    this.originalMessage = error![0].message;
  }

  getDetails() {
    return {
      name: this.name,
      code: this.code,
      message: this.message,
      originalMessage: this.originalMessage,
    };
  }
}
