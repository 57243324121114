import { QueryClientProvider } from "react-query";
import { SessionProvider } from "next-auth/react";

import { UIProvider } from "@releaseit/ui";

import { queryClient } from "./react-query";

const REFRESH_INTERVAL_SEC = 14.5 * 60; // 30 seconds before session expires

export type ProvidersProps = {
  /**
   * React children prop.
   */
  children: React.ReactNode;

  /**
   * NextJS Page Props that may be required for SSR.
   */
  pageProps: any;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Providers({ children, pageProps }: ProvidersProps) {
  return (
    <SessionProvider session={pageProps.session} refetchInterval={REFRESH_INTERVAL_SEC}>
      <QueryClientProvider client={queryClient}>
        <UIProvider>{children}</UIProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default Providers;
