import { MutationCache, QueryCache, QueryClient } from "react-query";
import Router from "next/router";

import { notifications } from "@releaseit/ui";

import { SanitizedGQLError } from "@/types/errors/SanitizedGQLError";

function defaultErrorHandler(error: unknown) {
  if (error instanceof SanitizedGQLError) {
    if (error.code === "UNAUTHENTICATED") {
      Router.push({
        pathname: "/login",
        query: { callbackUrl: Router.asPath },
      });
    } else {
      notifications.show({
        tone: "negative",
        title: "Something went wrong...",
        message: error.message,
      });
    }
  }
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({ onError: defaultErrorHandler }),
  mutationCache: new MutationCache({ onError: defaultErrorHandler }),
  defaultOptions: {
    queries: {
      retry: 2,
      useErrorBoundary: (error) => error instanceof SanitizedGQLError,
    },
  },
});
