"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BREAKPOINTS = exports.ASPECT_RATIO = exports.SHADOWS = exports.RADII = exports.FONT_WEIGHTS = exports.FONT_SIZES = exports.FONTS = exports.SIZING = exports.SPACING = exports.COLORS = void 0;
const colors_1 = require("./colors");
/**
 * Design tokens
 */
exports.COLORS = {
    ...colors_1.PALETTE,
    ...colors_1.COLOR_ALIASES,
};
exports.SPACING = {
    px: "1px",
    0: "0px",
    0.5: "0.125rem",
    1: "0.25rem",
    1.5: "0.375rem",
    2: "0.5rem",
    2.5: "0.625rem",
    3: "0.75rem",
    3.5: "0.875rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
};
exports.SIZING = {
    ...exports.SPACING,
};
exports.FONTS = {
    sans: "Aeonik, system-ui, -apple-system, sans-serif",
    serif: "Georgia, Cambria, 'Times New Roman', Times, serif",
    mono: "Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
};
exports.FONT_SIZES = {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
};
exports.FONT_WEIGHTS = {
    normal: 400,
    medium: 500,
};
exports.RADII = {
    none: "0",
    sm: "2px",
    md: "5px",
    lg: "10px",
    full: "9999px",
};
exports.SHADOWS = {
    sm: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
    lg: "0 8px 24px 0 rgba(51, 51, 51, 0.1), 0 8px 12px 0 rgba(0, 255, 81, 0.1)",
    card: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    none: "none",
};
exports.ASPECT_RATIO = {
    auto: "auto",
    square: "1 / 1",
    display: "4 / 3",
    video: "16 / 9",
};
exports.BREAKPOINTS = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
};
